.navbar {
  padding: 20px 0;
  border-bottom: 0;
  letter-spacing: 1px;
  background: 0 0;
  -webkit-transition: background .5s ease-in-out,padding .5s ease-in-out;
  -moz-transition: background .5s ease-in-out,padding .5s ease-in-out;
  transition: background .5s ease-in-out,padding .5s ease-in-out;
}

.navbar-brand {
  height: 40px;
  padding: 5px 15px;
  font-size: 18px;
  line-height: 1em;
  font-weight: 700;
}

.navbar-brand h1{
  color: #fff;
  font-size: 20px;
  line-height: 40px;
}

.navbar-brand:focus {
    outline: 0;
}

.nav.navbar-nav {
   background-color: @brand-color;
}

.navbar-custom.top-nav-collapse .nav.navbar-nav {
    background-color: @brand-color;
}

.navbar-custom ul.nav li a {
	font-size: 12px;
	letter-spacing: 1px;
    color: white;
	text-transform: uppercase;
	font-weight: 700;
}

.navbar-custom.top-nav-collapse ul.nav li a {
	-webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
	color: #fff;
}

.navbar-custom ul.nav ul.dropdown-menu {
	  border-radius: 0;
}

.navbar-custom ul.nav ul.dropdown-menu li {
	border-bottom: 1px solid #f5f5f5;

}

.navbar-custom ul.nav ul.dropdown-menu li:last-child{
	border-bottom: none;
}

.navbar-custom ul.nav ul.dropdown-menu li a {
	padding: 10px 20px;
}

.navbar-custom ul.nav ul.dropdown-menu li a:hover {
	background: rgba(255,255,255,.3);
}

.navbar-custom.top-nav-collapse ul.nav ul.dropdown-menu li a {
	color: #fff;
}

.navbar-custom .nav li a {
    -webkit-transition: background .3s ease-in-out;
    -moz-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
}

.navbar-custom .nav li a:hover,
.navbar-custom .nav li a:focus,
.navbar-custom .nav li.active {
    outline: 0;
    background-color: rgba(255,255,255,.3);
}

.navbar-toggle {
    padding: 4px 6px;
    font-size: 14px;
    color: #fff;
}

.navbar-toggle:focus,
.navbar-toggle:active {
    outline: 0;
}

.dropdown-menu{
  background-color: rgba(103,176,209,.8);
}

.top-nav-collapse {
  padding: 0;
  background-color: @brand-color;
}

.navbar-custom.top-nav-collapse {
  border-bottom: 1px solid rgba(255,255,255,.3);
}

.top-nav-collapse .dropdown-menu{

  background: #67b0d1;
}

.top-nav-collapse .dropdown-menu li a{

  color:white;
}

.intro {
  width:100%;
  position:relative;
  background: url(../../img/bg1.jpg) no-repeat top center;
  height: 100%;
  padding: 0;
}

.brand-heading {
  font-size: 100px;
}

.intro-text {
  font-size: 25px;
}

#intro-small{
  height:100px;
  padding-top:0px;
}