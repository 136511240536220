/* ===========================
--- Footer
============================ */

footer {
  width: 100%;
  bottom: 0;
  padding-top: @padding-top;
  padding-bottom: @padding-bottom;
  background-color: #67b0d1;
  color: white;
}

footer a {
  color: white;
}

// Language switcher
.language-bar-switcher ul {
  padding-left: 3em;
}

.language-bar-switcher li {
  display: inline-block;
  margin: 0 !important;
  padding: 0;
  color: white
}

.language-bar-switcher li a {
  color: white;
  padding-left: 1em;
}

// Social links
.company-social {
  margin-left: 0;
  padding-left: 0;
  margin-top: 10px;
}

.company-social {
  text-align: right;
  padding-right: 3em;
}

.company-social li{
  display: inline-block;
  margin:0 !important;
  padding-left: 2em;
}

.company-social a{
  margin:0;
  padding:0;
  display: block;
  text-align: center;
}

.company-social a i{
  text-align: center;
  margin:0;
  padding:0;
}

.contact {
  padding-left: 3em;
}