.meeting-container {
  margin-top: @margin-top;
  margin-bottom: @margin-bottom;
}

meeting > h2 {
  margin-top: @margin-top;
  margin-bottom: @margin-bottom;
}

meeting > .body {
  margin-top: @margin-top;
}

meeting > .description, meeting > .creator {
  margin-bottom: @margin-bottom;
}