html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-family: @font-family;
  font-weight: @font-weight;
  color: @text-color;
  background-color: @background-color;
}



h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 30px;
  text-transform: uppercase;
  font-family: @font-family;
  font-weight: @font-weight-big;
  letter-spacing: 1px;
}

p {
  margin: 0 0 20px;
  font-size: 16px;
  line-height: 1.6em;
}

a {
  color: @link-color;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

a:hover,
a:focus {
  text-decoration: none;
  color: @link-hover-color;
}

li .active {
  font-weight: @font-weight-big;
}